export const NavbarMenu = [
    {
        name: "Home",
        link: "/",
    },
    {
        name: "About us",
        link: "/aboutus",
    },
    {
        name: "Contact us",
        link: "/contact",
    },
    {
        name: "FAQ's",
        link: "/faq",
    }
    
    
];